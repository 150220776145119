export const ENTITLEMENT_TIERS = {
  enterprise: 'enterprise',
  essentials: 'essentials',
  free: 'free',
};

export const ENTITLEMENT_TITLE = {
  checklists: 'Readiness Checklist',
  status_pages: 'Status Page',
  jira_multi_projects: 'Jira Multi-Project',
  runbooks: 'Runbook',
  service_limit: 'Service',
  users: 'User',
};
