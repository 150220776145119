import { gql } from 'graphql-request';
import internalGQL from 'helpers/internalGQL';
import { useMutation, useQuery } from 'react-query';

import firehydrantAPI from '../../helpers/firehydrantAPI';
import internalAPI from '../../helpers/internalAPI';

const BASE_CURRENT_USER_URL = 'internal_api/v1/current_user';
const BASE_INTERNAL_USER_URL = 'internal_api/v1/users';

// Current User
export function useCurrentAuthContextGql(options = {}) {
  const gqlQuery = gql`
    query {
      currentAuthContext {
        __typename

        id
        name
        email
        isSuperDuperUser
        featureFlagIdentity

        account {
          id
          name
          tier
        }

        defaultOrganization {
          id
          name
          slug
        }

        organizations {
          edges {
            id
            role
            enabled
            hasRestrictedAccess
            isLinkedToSlack
            icalUrl
            tags

            token

            node {
              id
              name
              slug

              hasSsoConfigured

              metadata {
                showPriority
              }
            }
          }
        }
      }
    }
  `;

  const query = useQuery(
    ['current_user'],
    async () => {
      const data = await internalGQL().request(gqlQuery);

      return data;
    },
    options,
  );

  return {
    ...query,
    data: query?.data?.currentAuthContext || {},
  };
}

const downloadCurrentSlackAvatar = (body) => {
  const resp = internalAPI.put(
    'internal_api/v1/current_user/avatar/slack',
    body,
  );
  return resp;
};

export const useDownloadCurrentSlackAvatar = (body) =>
  useMutation(downloadCurrentSlackAvatar, body);

const updateCurrentUser = (params) => {
  const resp = internalAPI.put(`${BASE_CURRENT_USER_URL}`, params);
  return resp;
};

export const useUpdateCurrentUser = (params) =>
  useMutation(updateCurrentUser, params);

const updateCurrentUserPassword = (params) => {
  const resp = internalAPI.post(`${BASE_CURRENT_USER_URL}/password`, params);
  return resp;
};

export const useUpdateCurrentUserPassword = (params) =>
  useMutation(updateCurrentUserPassword, params);

const updateCurrentUserTags = (params) => {
  const resp = internalAPI.put(`${BASE_CURRENT_USER_URL}/tags`, params);
  return resp;
};

export const useUpdateCurrentUserTags = (params) =>
  useMutation(updateCurrentUserTags, params);

// Users
// v1/users
export const useUsers = (params = {}) => {
  const page = params.page || 1;
  const query = useQuery(['usersGet', page], () => firehydrantAPI.get('users'));

  return {
    ...query,
    data: query?.data?.data?.data || [],
    pagination: query?.data?.data?.pagination || {},
  };
};

// Internal Users API
// internal_api/v1/users
export const useInternalUsers = (params = {}, options = {}) => {
  const query = useQuery(
    ['usersGet', 'internal', params],
    () => internalAPI.get(BASE_INTERNAL_USER_URL, { params }),
    options,
  );

  return {
    ...query,
    data: query?.data?.data?.data || [],
    pagination: query?.data?.data?.pagination || {},
  };
};

export const useInternalUser = (id) => {
  const query = useQuery(['userGet', id, 'breadcrumbs'], () =>
    internalAPI.get(`${BASE_INTERNAL_USER_URL}/${id}`),
  );

  return {
    ...query,
    data: query?.data?.data || {},
  };
};

const internalDeleteUser = (id) => {
  const resp = internalAPI.delete(`${BASE_INTERNAL_USER_URL}/${id}`);
  return resp;
};

export const useInternalDeleteUser = (params) =>
  useMutation(internalDeleteUser, params);

const internalUpdateUser = (params) => {
  const resp = internalAPI.patch(
    `${BASE_INTERNAL_USER_URL}/${params.id}`,
    params,
  );
  return resp;
};

export const useInternalUpdateUser = (params) =>
  useMutation(internalUpdateUser, params);

const organizationInviteUser = (params) => {
  const resp = internalAPI.post('internal_api/v1/invitations', params);
  return resp;
};

export const useOrganizationInviteUser = (params) =>
  useMutation(organizationInviteUser, params);
