export const Table = {
  baseStyle: {
    a: {
      color: 'gray.800',
    },
    thead: {
      tr: {
        _hover: {
          bg: 'transparent',
        },
      },
    },
    th: {
      textTransform: 'initial',
      letterSpacing: 'normal',
      fontSize: 6,
      borderBottom: '1px solid',
      borderColor: 'gray.100',
    },
    tr: {
      borderBottom: '1px solid',
      borderColor: 'gray.100',
      '&:last-of-type': {
        borderBottom: 'none',
      },
      _hover: {
        bg: '#1767F207',
      },
    },
    td: {
      px: '3',
      py: '4',
    },
  },
  variants: {
    basic: {
      th: {
        fontSize: 6,
        px: '3',
        py: '3',
      },
      td: {
        fontSize: 6,
        px: '3',
        py: '4',
      },
    },
    dashboard: {
      th: {
        borderBottom: 'none',
      },
    },
  },
  defaultProps: {
    variant: 'basic',
    size: 'md',
  },
};
