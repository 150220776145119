export const DATETIME_FORMAT = {
  custom: 'custom',
  date: 'date',
  date_numeric: 'date_numeric',
  date_range: 'date_range',
  duration: 'duration',
  duration_short: 'duration_short',
  friendly: 'friendly',
  legacy: 'legacy',
  raw: 'raw',
  relative: 'relative',
  time: 'time',
  utc: 'utc',
  conditional: 'conditional',
  withDay: 'withDay',
  tight: 'tight',
};
