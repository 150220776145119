import React, { useEffect, useState } from 'react';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Flex,
} from '@chakra-ui/react';
import { useUserContext } from 'contexts/UserContext/UserContextProvider';
import PropTypes from 'prop-types';
import { ErrorBoundary as FallbackErrorBoundary } from 'react-error-boundary';

import { useToastContext } from './ToastBannerProvider/ToastBannerProvider';

function DefaultFallbackComponent({ error }) {
  return (
    <Flex alignItems="center" justifyContent="center">
      <Alert status="error">
        <AlertIcon />
        <AlertTitle>There was an error.</AlertTitle>
        {error && <AlertDescription>{error.message}</AlertDescription>}
      </Alert>
    </Flex>
  );
}

DefaultFallbackComponent.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  clearError: PropTypes.func,
  resetErrorBoundary: PropTypes.func,
};

function FallbackComponentWithToast({ error }) {
  const { addToast } = useToastContext();
  addToast({
    variant: 'error',
    message: error.message,
  });
  return null;
}

FallbackComponentWithToast.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

if (window.bugsnagAPIKey && !document.cookie?.includes('use_local_dev')) {
  Bugsnag.start({
    releaseStage: window.firehydrant_environment,
    apiKey: window.bugsnagAPIKey,
    plugins: [new BugsnagPluginReact()],
  });
}

const ErrorBoundaryComponent = Bugsnag.isStarted()
  ? Bugsnag.getPlugin('react').createErrorBoundary(React)
  : FallbackErrorBoundary;

export default function ErrorBoundary({ showToastMessage = false, ...props }) {
  const { currentUser, account, currentOrganization } = useUserContext();
  const [versions, setVersions] = useState({});

  const versionCheck = async () => {
    try {
      const body = await fetch('/infra/health').then((response) =>
        response.json(),
      );
      setVersions({
        revision: body.revision,
      });
    } catch (err) {
      if (Bugsnag.isStarted()) {
        Bugsnag.notify('Error fetching /infra/health in ErrorBoundary');
      }
    }
  };

  useEffect(() => {
    versionCheck();
  }, []);

  const gitRevision = versions?.revision;

  if (Bugsnag.isStarted()) {
    Bugsnag.setUser(currentUser?.id, currentUser?.email, currentUser?.name);
    Bugsnag.addMetadata('tags', currentUser?.tags);
    Bugsnag.addMetadata('app', {
      appVersion: gitRevision,
    });
    Bugsnag.addMetadata('account', {
      id: account?.id,
      name: account?.name,
    });
    Bugsnag.addMetadata('organization', {
      id: currentOrganization?.node?.id,
      name: currentOrganization?.node?.name,
    });
  }

  function defaultErrorHandler(error, info) {
    if (
      Bugsnag.isStarted() &&
      error?.response?.status >= 500 &&
      process.env.NODE_ENV === 'production'
    ) {
      Bugsnag.leaveBreadcrumb(
        'requestFailure',
        {
          status: error.response.status,
          responseText: error.request.responseText,
          duration: new Date() - error.config.metadata.startTime,
        },
        'request',
      );
    } else {
      console.error(error, info);
    }
  }

  return (
    <ErrorBoundaryComponent
      onError={defaultErrorHandler}
      FallbackComponent={
        showToastMessage ? FallbackComponentWithToast : DefaultFallbackComponent
      }
      {...props}
    />
  );
}
ErrorBoundary.propTypes = {
  showToastMessage: PropTypes.bool,
};
