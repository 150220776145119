import React from 'react';

import {
  Box,
  Card,
  Flex,
  Icon,
  Link,
  SkeletonText,
  Text,
} from '@chakra-ui/react';
import { IncidentMSTeamsChannelLink } from '@firehydrant/design-system';
import PropTypes from 'prop-types';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { IoMdLock } from 'react-icons/io';

import { SparklineChart, WatchButton } from '../../components';
import { IncidentMilestoneBadge } from '../incident-milestone-badge';
import { IncidentSeverityBadge } from '../incident-severity-badge';
import { IncidentSlackChannelLink } from '../incident-slack-channel-link';
import { IncidentStatusPageLink } from '../incident-status-page-link';
import { IncidentPropType } from '../prop-types';

export function IncidentCard({
  incident,
  onToggleWatch,
  incidentLinkProps,
  events,
  chartLabel,
  ...props
}) {
  const isLoading = !incident;
  return (
    <Card
      minWidth="240px"
      maxWidth="800px"
      position="relative"
      _focusWithin={
        isLoading
          ? null
          : {
              boxShadow: 'lg',
            }
      }
      _hover={
        isLoading
          ? null
          : {
              boxShadow: 'lg',
            }
      }
      cursor={isLoading ? null : 'pointer'}
      {...props}
    >
      <Flex direction="column" height="100%" borderTopRadius="inherit">
        <Box flex="1">
          <Flex ml="3" mr="2" mt="2" gap="2" alignItems="center">
            <Box flex="1">
              <IncidentDuration
                isLoading={isLoading}
                duration={incident?.duration}
              />
            </Box>
            <Flex gap="2">
              <IncidentStatusPageLink
                isLoading={isLoading}
                url={incident?.links?.privateStatusPage}
                zIndex="1"
              />
              <IncidentSlackChannelLink
                isLoading={isLoading}
                url={incident?.links?.slackChannel}
                zIndex="1"
              />
              <IncidentMSTeamsChannelLink
                isLoading={isLoading}
                url={incident?.links?.msTeamsChannel}
                zIndex="1"
              />
            </Flex>
          </Flex>

          <IncidentName
            isLoading={isLoading}
            incident={incident}
            mx="3"
            mt="1"
            mb="3"
            linkProps={incidentLinkProps}
          />

          <Flex gap="1" mx="3" mb="3">
            <IncidentSeverityBadge
              isLoading={isLoading}
              value={incident?.severity}
              color={incident?.severity_color}
            />
            <IncidentMilestoneBadge isLoading={isLoading} incident={incident} />
          </Flex>
        </Box>

        <Flex m="3" direction="column" gap="3" flexGrow="0">
          <SparklineChart
            isLoading={isLoading || events?.isLoading}
            data={events?.activity}
            label={chartLabel}
          />
        </Flex>
      </Flex>
      <Flex alignItems="center" pl="3" pb="2" pr="2" fontSize="sm" flexGrow="0">
        <Box flex="1">
          <SkeletonText
            isLoaded={!isLoading}
            noOfLines={1}
            my="1"
            mr="1"
            w="15ch"
            {...props}
          >
            <Text as="span" fontSize="xs" color="gray.500" fontWeight="normal">
              {incident?.role || ''}
            </Text>
          </SkeletonText>
        </Box>
        <WatchButton
          isDisabled={isLoading}
          isSelected={incident?.isWatched}
          onChange={onToggleWatch}
          id={incident?.id}
          zIndex="2"
        />
      </Flex>
    </Card>
  );
}
IncidentCard.propTypes = {
  incident: IncidentPropType,
  events: PropTypes.shape({
    isLoading: PropTypes.bool,
    activity: SparklineChart.propTypes.data,
  }),
  onToggleWatch: PropTypes.func,
  incidentLinkProps: PropTypes.object,
  chartLabel: PropTypes.string,
};

function IncidentDuration({ isLoading = false, duration, ...props }) {
  // TODO: move DateTime, ElapsedTime into the DS and use them here
  if (isLoading === false && !duration) {
    return null;
  }
  return (
    <SkeletonText
      isLoaded={duration || isLoading === false}
      noOfLines={1}
      w={isLoading ? '10ch' : 'auto'}
      {...props}
    >
      <Flex gap="1" alignItems="center">
        <Icon as={AiOutlineClockCircle} boxSize="12px" color="gray.600" />
        <Box flex="1" fontSize="xs" color="gray.600">
          {duration}
        </Box>
      </Flex>
    </SkeletonText>
  );
}
IncidentDuration.propTypes = {
  isLoading: PropTypes.bool,
  duration: PropTypes.node,
};

function IncidentName({
  isLoading = false,
  linkProps = { as: 'span' },
  incident,
  ...props
}) {
  if (isLoading === false && !incident?.name) {
    return null;
  }

  return (
    <SkeletonText
      isLoaded={incident?.name || isLoading === false}
      noOfLines={2}
      {...props}
    >
      <Box lineHeight="shorter">
        {incident?.isPrivate && (
          <Icon
            as={IoMdLock}
            color="gray.700"
            mt="-1px"
            boxSize="16px"
            verticalAlign="middle"
          />
        )}
        {incident?.id ? (
          <Link
            {...linkProps}
            fontWeight="semibold"
            _after={{
              content: '" "',
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              borderRadius: 'inherit',
            }}
            fontSize="6"
          >
            {incident?.name}
          </Link>
        ) : (
          incident?.name
        )}
        {incident?.number && (
          <Text
            as="span"
            fontWeight="medium"
            fontFamily="mono"
            color="gray.600"
            fontSize="xs"
            ml="4px"
          >
            #{incident?.number}
          </Text>
        )}
      </Box>
    </SkeletonText>
  );
}
IncidentName.propTypes = {
  isLoading: PropTypes.bool,
  incident: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isPrivate: PropTypes.bool,
    severity: PropTypes.string,
    severity_color: PropTypes.string,
  }),
  linkProps: PropTypes.object,
};
