import React from 'react';

import { Box, Flex, Link, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import logo from '../../../assets/images/logo-with-text.png';

export function GlobalError({ clearError, resetErrorBoundary }) {
  const resetErrors = clearError || resetErrorBoundary;
  return (
    <Flex direction="column" height="100vh" width="100%">
      <Flex direction="column" alignItems="flex-start" pt="20px" pl="20px">
        <img src={logo} alt="Firehydrant logo" height="60px" />
      </Flex>
      <Flex
        direction="column"
        mt="100px"
        gap="4"
        alignItems="center"
        width="100%"
      >
        <Box
          p="30px"
          maxWidth="730px"
          border="solid 1px #E2E2E2"
          borderRadius="lg"
          height="fit-content"
        >
          <Text as="h1" fontSize="36px" fontWeight="500" pb={8} color="#373542">
            Oops! There was an unexpected error.
          </Text>
          {resetErrors ? (
            <Text fontSize="30px" color="#373542">
              <Text
                as="span"
                mb={0}
                onClick={resetErrors}
                textDecoration="underline"
                _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                Try again
              </Text>{' '}
              or{' '}
              <Link
                href="https://firehydrant.zendesk.com/hc/en-us/requests/new "
                target="_blank"
                textDecoration="underline"
                _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                color="#373542"
              >
                contact us
              </Link>{' '}
              for assistance.
            </Text>
          ) : (
            <Text as="p" fontSize="30px" color="#373542">
              Please refresh this page to try again, or{' '}
              <Link
                href="https://firehydrant.zendesk.com/hc/en-us/requests/new "
                target="_blank"
                textDecoration="underline"
                _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                contact us
              </Link>{' '}
              for assistance.
            </Text>
          )}
        </Box>
      </Flex>
    </Flex>
  );
}

GlobalError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  clearError: PropTypes.func,
  resetErrorBoundary: PropTypes.func,
};
